const initialState = []

export default function messageReducer(state = initialState, action = {}) {

  switch(action.type) {

    case 'MESSAGE_ADD_REQUEST':
      return [...state, action.payload]

    case 'MESSAGE_REMOVE_REQUEST':
      return state.filter((message) => {
        return message !== action.payload
      })

    default:
      return state

  }
  
}
