// TO DOS
// - import EnhancedTextField and use that, so this can be cleaned up
// - create EnhancedRadioButton & EnhancedSelect, so this can be used as well.
// - prisitine comes from the props????? Not redux-form?

import React, { useEffect } from 'react'
import { Field, reduxForm, formValues } from 'redux-form'
import { compose } from 'redux' // To string together stuff in export at the bottom
import { withStyles } from '@material-ui/core/styles' // To apply styles on the drawer

// Material ui components
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

// Customer components
import EnhancedTextField from 'components/EnhancedTextField/EnhancedTextField'
// import EnhancedRadioGroup from 'components/EnhancedRadioGroup/EnhancedRadioGroup'
// import EnhancedCheckbox from 'components/EnhancedCheckbox/EnhancedCheckbox'
// import EnhancedTextarea from 'components/EnhancedTextarea/EnhancedTextarea'

// Action creators
import { required, email } from 'utils/validations'

import './contact.css'

const styles = theme => ({
  tab: {

  }
})


// - handleSubmit, submitting, change & initialize come from redux-form
// - dispatch to dispatch action creator to get stuff from collection
// - classes to be able to assign styling to the rendered stuff
// - onSendMail is passed in through the props of the Contact component
// - prisitine comes from the props????? Not redux-form?
const ContactForm = ({ handleSubmit, submitting, change, initialize, dispatch, classes, onSendMail, pristine }) => {

  useEffect(() => {
    initialize({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      remarks: ''
    })
  }, [initialize]) // only once


  return(
    <>
      <form onSubmit={handleSubmit} className="indented">
        <Grid container direction="column">
          <Grid item xs={6}>
            <Field
              name="firstName"
              label="Voornaam"
              component={EnhancedTextField}
              validate={required}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="lastName"
              label="Achternaam"
              component={EnhancedTextField}
              validate={required}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="phoneNumber"
              label="Telefoonnummer"
              component={EnhancedTextField}
              validate={required}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="email"
              label="Email"
              component={EnhancedTextField}
              validate={email}
            />
          </Grid>
          { /* <Grid item xs={6}>
            <Field
              name='gender'
              options={['Male', 'Female', 'Other']}
              direction='column'
              component={EnhancedRadioGroup}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name="myCheckbox"
              label="Awesome checkbox"
              checked={false}
              component={EnhancedCheckbox}
            />
          </Grid>
          */ }
          <Grid item xs={6}>
            <Field
              name="remarks"
              label="Bericht"
              component={EnhancedTextField}
              validate={required}
            />
          </Grid>
          <Grid item xs={6}>
            <br />
            <Button type="submit" variant="contained" color="primary" disabled={pristine || submitting}>
              Doorsturen
              <Icon>send</Icon>
            </Button>
            <br />
            <br />
          </Grid>

        </Grid>
      </form>
    </>
  )
}

export default compose(
  reduxForm({
    form: 'contactForm',
    destroyOnUnmount: false
  }),
  withStyles(styles)
)(ContactForm)
