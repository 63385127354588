// I18nLink extends rfr Link
import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// lang is gotten from i18n node of the state store (see bottom)
// to comes from the to attribute of <I18nLink>
const addLang = (to, lang) => {
  // assigning values from "to" to payload and rest
  const { payload, ...rest } = to
  // return rest (which is everything that to does plus all of the payload but with lang added/changed)
  return { ...rest, payload: { ...payload, lang } }
}

// children is whatever is inside of the <Link> tags
const I18nLink = ({ to, lang, children }) => {
  if (typeof to === 'string') {
    return <Link to={`/${lang}${to}`}>{children}</Link>
  } else {
    return <Link to={addLang(to, lang)}>{children}</Link>
  }
}

const mapState = ({ i18n: { lang } }) => ({ lang })
export default connect(mapState)(I18nLink)
