// Notes
//  1. Removed case of RecoverEmail:
// import RecoverEmail from './RecoverEmail'
// case 'recoverEmail':
//   return <RecoverEmail />
//   break

import React, { Fragment } from 'react'
import { connect } from 'react-redux' // to have dispatch, so we can reset form

import ResetPassword from './ResetPassword'
import VerifyEmail from './VerifyEmail'

// To get oobCode from url
import { getParameterByName } from 'utils/getParameter'

// To give feedback
import Popup from 'components/Popup/Popup'
import { addMessage } from 'actions/messages'

const AccountManagement = ({dispatch}) => {

  // Get stuff from url to determine what type of account management it is
  const mode = getParameterByName('mode')
  const actionCode = getParameterByName('oobCode') // one time code
  // const continueUrl = getParameterByName('continueUrl')
  // const lang = getParameterByName('lang') || 'nl'

  // Handle the account management action based on the mode
  function renderForm() {
    switch (mode) {
      case 'resetPassword':
        return <ResetPassword actionCode={actionCode} />
      case 'verifyEmail':
        return <VerifyEmail actionCode={actionCode} />
      default:
        dispatch(addMessage('Ongeldige actie van account management!'))
    }
  }

  return(
    <Fragment>
      { renderForm() }
      <Popup />
    </Fragment>
  )
}

export default connect()(AccountManagement)
