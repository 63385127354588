import { firebase } from '../firebase'
import { PRODUCTS_SUCCESS } from './types'
import { List } from 'immutable'

export const productsRequest = () => {
  return (dispatch) => {
    return firebase
      .database()
      .ref('products')
      .orderByChild('position')
      .once('value', (snapshot) => {
        let products = List()
        snapshot.forEach(function(childSnapshot) {
          products = products.push({
            id: childSnapshot.key,
            name: childSnapshot.val().name,
            shortDescription: childSnapshot.val().shortDescription,
            longDescription: childSnapshot.val().longDescription,
            meta_title: childSnapshot.val().meta_title,
            meta_description: childSnapshot.val().meta_description,
            img: childSnapshot.val().img,
            price: childSnapshot.val().price,
            category: childSnapshot.val().category,
            subCategory: childSnapshot.val().subCategory
          })
        })
        dispatch({ type: PRODUCTS_SUCCESS, payload: products })
      })
  }
}
