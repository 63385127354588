const initialState = null

export default function userReducer(state = initialState, action = {}) {

  switch (action.type) {

    case 'USER_EMAIL_VERIFICATION_SUCCESS':
    case 'USER_LOGIN_SUCCESS':
    case 'USER_FETCH_SUCCESS':
      return action.payload

    case 'USER_LOGOUT_SUCCESS':
      return initialState

    default:
      return state

  }

}
