// Default react stuff
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux' // Dispatch comes from react-redux (connect), we need it here to dispatch action to get
import { Route, withRouter } from 'react-router-dom'

// Material-ui for the styling
import Grid from '@material-ui/core/Grid'

// Custom components
import Menu from 'components/Menu'
import Banner from 'components/Banner'
import Footer from 'components/Footer/Footer'
import Loader from 'components/Loader/Loader'
import ScrollUpButton from 'react-scroll-up-button'
import Page from 'components/Page/Page.js' // when path={`${match.url}/page/:id`} => Page is shown
import Product from 'components/Product' // when path="/:lang/products/:id" => Product is shown

// Actions to be dispatched so the loading reducer and selector can pick up on them
import { I18N_REQUEST, MENU_REQUEST, PAGES_REQUEST, STYLES_REQUEST, PANELS_REQUEST, PRODUCTS_REQUEST } from 'actions/types'

// Action creators
import { menuRequest } from 'actions/menu' // fetching the menu items
import { pagesRequest } from 'actions/pages' // fetching the content of the pages
import { i18nRequest } from 'actions/i18n' // fetching the translations
import { stylesRequest } from 'actions/styles' // fetching the styling the user can customize
import { panelsRequest } from 'actions/panels' // fetching the panels
import { productsRequest } from 'actions/products' // fetching the products
import { authListener } from 'actions/users' // to check whether is already logged in on firebase (to survive refresh)
import { createLoadingSelector } from 'selectors/createLoadingSelector' // to have loading state, during which Loader is displayed

// - dispatch comes from connect, which we use to dispatch action creators to pull in stuff from firebase, trigger a reducer into putting it into the redux-store
// - match comes from react-router-dom (which is connected with withRouter at the bottom too) and is used to get the lang parameter from the url
// - isFetching is a boolean value that indicates whether there is still something being fetched
const App = ({dispatch, match, isFetching}) => {

  useEffect(() => {
    dispatch({ type: I18N_REQUEST }) // To indicate that the loading part has begun
    dispatch({ type: PAGES_REQUEST })
    dispatch({ type: MENU_REQUEST })
    dispatch({ type: STYLES_REQUEST })
    dispatch({ type: PANELS_REQUEST })
    dispatch({ type: PRODUCTS_REQUEST }) // Products is also in here, in case someone has a link to a product and they click it
    dispatch(menuRequest()) // Dispatching the action creators, so data can be retrieved from collections and stuck in redux-store by reducers
    dispatch(pagesRequest())
    dispatch(panelsRequest())
    dispatch(productsRequest())
    dispatch(stylesRequest())
    dispatch(authListener()) // And launch listener, that reacts when the auth state changes in firebase (when someone logs in or out)
  }, [dispatch])

  // We used to get language from the redux store, but that would be nl by default, so we have to pass
  // in the lang in the url instead and we have it in useEffect hook that reloads when the lang changes,
  // so that way it changes in redux-store as well.
  useEffect(() => {
    dispatch(i18nRequest(match.params.lang))
  }, [match.params.lang, dispatch])

  return (
    <Fragment>
      { isFetching
        ?
        <Loader />
        :
        <div>
          <Menu />
          <ScrollUpButton />
          <Grid container direction="column" alignItems="center" className="App" wrap="nowrap" spacing={0}>
            <Grid item id="banner" xl={10} lg={10} md={11} sm={12} xs={12}>
              <Banner />
            </Grid>
            <Grid item id="mainContainer" xl={10} lg={10} md={11} sm={12} xs={12}>
              <Route path={`${match.url}/page/:id`} component={Page} />
              <Route path="/:lang/products/:id" component={Product} />
            </Grid>
            <Grid item id="footer" xl={10} lg={10} md={11} sm={12} xs={12}>
              <Footer />
            </Grid>
          </Grid>
        </div>
      }
    </Fragment>
  )
}

// The site is only shown once aaaaaaaall of this is loaded (until then you have the animated gif from Loader component)
const loadingSelector = createLoadingSelector(['I18N', 'PAGES', 'MENU', 'STYLES', 'PANELS', 'PRODUCTS'])
const mapState = (state) => ({ isFetching: loadingSelector(state) })
export default connect(mapState)(withRouter(App))
