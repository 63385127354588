// REMARKS
// -------
// 1) There is a logout button, which can be clicked when logged in,
//    but when you close the window you will already be logged out (setting Firebase)

// TO DOS
// - What is difference between app & firebase
// - Perhaps replace menu.css by styling in the component? That doesn't cause interference with other components

// Standard react and redux stuff
import React, { useState } from 'react'
import { connect } from 'react-redux' // For getting stuff from the redux store (at the bottom) and for dispatching actions
import { compose } from 'redux' // To string together stuff in export at the bottom

// Material-ui components
import AppBar from '@material-ui/core/AppBar' // Element to put the menu inside of, which can be styled easily
import Hidden from '@material-ui/core/Hidden' // To hide the AppBar when on smaller screen
import Button from '@material-ui/core/Button' // to show the hamburger on smaller screen
import Paper from '@material-ui/core/Paper' // Paper can be used to build a surface in your application, here it's used to put the menu on
import ClickAwayListener from '@material-ui/core/ClickAwayListener' // When you click away from the burger that the menu collapses
import Icon from '@material-ui/core/Icon' // For the burger icon itself
import Slide from '@material-ui/core/Slide' // For the movement (slide in) when the hamburger is pressed
import Dialog from '@material-ui/core/Dialog' // To have the dialog modal to log in
import Tabs from '@material-ui/core/Tabs' // Tabs to have login / register / password lost / change password
import Tab from '@material-ui/core/Tab'

// Describe
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import SwipeableViews from 'react-swipeable-views'
import PropTypes from 'prop-types'

// Custom components
import I18nLink from 'components/I18n/I18nLink.js' // for links and translations
import I18n from 'components/I18n/I18n.js'
import LoginForm from './LoginForm' // 3 forms (login, register & forgotten password), which are displayed in Dialog
import RegisterForm from './RegisterForm'
import ForgottenPasswordForm from './ForgottenPasswordForm'
import ShoppingCart from '../Shop/ShoppingCart' // The ShoppingCart drawer that slides in from the right when icon is pressed

// Action creators
import { withStyles } from '@material-ui/core/styles' // To apply styles on the drawer
import { logOut } from 'actions/users.js' // When logout button is pressed this action is dispatched

// Styling
import './menu.css'

const styles = theme => ({
  tab: {
    minWidth: 50,
    height: "100%",
    alignItems: "flex-start",
    paddingBottom: 5,
    wordWrap: "break-word",
    flex: 1,
    overflowWrap: "break-word",
    textAlign: "center"
  },
  loginBtn: {
    display: 'block',
    padding: 7,
    color: '#222',
    fontSize: '.9em',
    letterSpacing: 1,
    textDecoration: 'none',
    '&:hover': {
       cursor: 'pointer'
    }
  }
})


// New tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// - Menu, shoppingCart & user come from the redux-store
// - classes comes from the props of this component, so we can style elements
// - dispatch comes from react-redux's connect and is used to dispatch actions
const Menu = ({menu, shoppingCart, user, classes, dispatch}) => {

  const[open, setOpen] = useState(false) // whether or not the dialog with login / register / forgotten password is open
  const[activeItem, setActiveItem] = useState('home') // the menu item that's selected by default
  const[loginModalVisible, setLoginModalVisible] = useState(false) // whether or not the login modal is visible
  const[value, setValue] = useState(0) // index of login (0), register (1) & forgotten password (2)
  const[shoppingCartDrawer, setShoppingCartDrawer] = useState(false) // visibility of the shopping cart drawer

  // Hamburger
  const handleClick = () => setOpen(!open) // opening
  const handleClickAway = () => setOpen(false) // closing

  // Menu items
  const selectItem = (item) => setActiveItem(item) // when clicking on menu item => change active item

  // Login dialog modal
  const loginModal = () => setLoginModalVisible(true) // opening
  const closeLoginModal = () => setLoginModalVisible(false) // closing
  const handleChange = (event, newValue) => setValue(newValue) // changing between login (0), register (1) & forgotten password (2)
  const handleChangeSwipe = (index) => setValue(index) // the same as above, but when swiping

  // Shopping cart
  const showShoppingCart = () => setShoppingCartDrawer(true) // opening
  const hideShoppingCart = () => setShoppingCartDrawer(false) // closing

  // Properties of the tab sheets in the login modal
  function tabProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    }
  }

  // Construction of the menu itself
  const menuItems = menu.map(function(item, i) {
    // I added visibility field, to hide items
    if(item.visible === true) {
      // Checking whether there are children, so it needs to be a dropdown
      if (item.children.length > 0) {
        return (
          <li key={item.key} onClick={(event) => selectItem(item.key)} className={(item.key === activeItem ? "menuItem active" : "menuItem")}>
            {/* <I18nLink to={'/page/' + item.key}> */}
            <I18nLink to={'/page/klankcirkels'}>
              <I18n label={item.name_button} />
            </I18nLink>
            <ul>
              {item.children.map(function(subItem, j) {
                return (
                  <li key={subItem.key} className={(subItem.key === activeItem ? "menuItem active" : "menuItem")}>
                    <I18nLink to={'/page/' + subItem.key}>
                      <I18n label={subItem.name_button} />
                    </I18nLink>
                  </li>
                )
              })}
            </ul>
          </li>
        )
      } else {
        return (
          <li key={item.key} onClick={(event) => selectItem(item.key)} className={(item.key === activeItem ? "menuItem active" : "menuItem")}>
            <I18nLink to={'/page/' + item.key}>
              <I18n label={item.name_button} />
            </I18nLink>
          </li>
        )
      }
    }
  })
  // Adding the button to open the login modal
  // .concat(
  //   <li key="login" onClick={loginModal} className={classes.loginBtn}>
  //     <span>Login</span>
  //   </li>
  // // Adding the shoppingCart button, which opens the shoppingCart drawer
  // ).concat(
  //   <span key="shoppingCart">
  //     <Icon onClick={showShoppingCart} className="shoppingCartIcon">shopping_cart</Icon>
  //     <span className="shoppingCartSize">{ shoppingCart.size }</span>
  //     <span className="displayName">{ user && user.emailVerified ? ` (${user.displayName} ingelogd)` : '' }</span>&nbsp;
  //     <span className="logout" onClick={(event) => dispatch(logOut())}>{ user && user.emailVerified ? 'Uitloggen' : '' }</span><br />
  //   </span>
  // )

  return (
    <AppBar className="responsiveAppBar" position="static">

      <Hidden smUp>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <Button onClick={handleClick}>
              <Icon>menu</Icon>
            </Button>
              { open ? (
              <Slide direction="up" in={open} mountOnEnter>
                <Paper>
                  <nav>
                    <ul>{menuItems}</ul>
                  </nav>
                </Paper>
              </Slide>
              ) : null }
          </div>
        </ClickAwayListener>
      </Hidden>

      <Hidden xsDown>
        <nav>
          <ul>{menuItems}</ul>
        </nav>
      </Hidden>

      <Dialog
        open={loginModalVisible}
        onClose={closeLoginModal}
      >

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor={"primary"}
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Login" {...tabProps(0)} />
          <Tab label="Register" {...tabProps(1)} />
          <Tab label="Paswoord vergeten" {...tabProps(2)} />
        </Tabs>

        <SwipeableViews
          axis="x"
          index={value}
          onChangeIndex={handleChangeSwipe}
        >
          <TabPanel value={value} index={0}>
            <LoginForm closeLoginModal={closeLoginModal} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RegisterForm closeLoginModal={closeLoginModal} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ForgottenPasswordForm closeLoginModal={closeLoginModal} />
          </TabPanel>
        </SwipeableViews>

      </Dialog>

      <ShoppingCart
        open={shoppingCartDrawer}
        close={hideShoppingCart}
      />

    </AppBar>
  )

}

// To get the menu from the state store
const mapState = ({ menu, shoppingCart, user }) => ({ menu, shoppingCart, user })
export default compose(
  withStyles(styles),
  connect(mapState)
)(Menu)
