// To dos
// - type / settype default value has to be better?

import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon' // to have the eye ball thingy
import InputAdornment from '@material-ui/core/InputAdornment' // for the eye thingy to make password invisible
import { withStyles } from '@material-ui/core/styles' // to put styles in this component (biggest advantage over just css is that this style is only applied in this component and doesn’t possibly interfere with other components

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    marginTop: 10,
    width: 291.64
  },
  errorMessage: {
    color: 'red'
  }
})

// - input comes from Field
// - label will be used to determine and set the type
// - meta keeps whether the field is touched and whether it doesn't validate
// - classes can be put in this subcomponent as well, so that makes the parent slightly less cluttered
const EnhancedTextField = ({ input, label, validate, meta: {touched, error}, classes }) => {

  const [type, setType] = useState(( label === 'Paswoord' ? 'password' : ( label.indexOf('datum') !== -1 ? 'date' : 'input' ) ))
  const [visibility, setVisibility] = useState('visibility')
  const inputLabelShrink = ( label === 'Factuurdatum' ? {shrink: true} : {} )

  // if the label is paswoord put the eyeball icon at the end. We can probably just have that function in this functional component as well
  const inputProps = (
    label === 'Paswoord' ?
    { endAdornment: <InputAdornment position="end"><Icon onClick={() => handlePasswordVisibility(type, setType)}>{ visibility }</Icon></InputAdornment> } :
    {}
  )

  // Toggle visibility when eye ball is clicked
  function handlePasswordVisibility(type, setType) {
      setType(type === 'input' ? 'password' : 'input')
      setVisibility(visibility === 'visibility_off' ? 'visibility' : 'visibility_off')
  }

  return(
    <div className={classes.container}>
      <TextField
        fullWidth
        label={label}
        error={touched && !!error}
        type={type}
        className={classes.textField}
        InputProps={ inputProps }
        InputLabelProps={inputLabelShrink}
        {...input}
      />
      { touched && error && <span className={classes.errorMessage}>{ error }</span> }
    </div>
  )

}

export default withStyles(styles)(EnhancedTextField)
