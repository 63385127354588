// Default react stuff
import ReactDOM from 'react-dom'
import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { Router, Route, Redirect, Switch } from 'react-router-dom' // Instead of React-First-Router we now use React-Router, to manage everything with regards to the routes

// Material UI
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

// Custom components
import App from './App'

// Utilities
import configureStore from 'utils/configureStore' // The configuring of the redux store (global state of application), the nodes where the reducers write stuff when triggered by actions
import history from 'utils/history' // Managing the history stack

import './index.css' // General stylesheets (components can have separate stylesheets as well)

const store = configureStore() // Creation of an instance of the redux store and browser history

const THEME = createMuiTheme({
  overrides: {
    MuiButton: {
      text: {
        '&:hover': {
          backgroundColor: '#F9F1D5',
          color: 'black',
          cursor: 'pointer',
          fontWeight: 'bold'
        },
        backgroundColor: '#d7d1be',
        color: 'white',
        width: 150,
        textAlign: 'center',
        marginTop: 15
      }
    }
  }
})

// Here we do high level routing:
//  - / gets redirected to /nl/page/home so that goes into the /:lang route and mounts App
//  - /:lang mounts the App component under any route that matches /:lang (the site's page routing itself is in there)
ReactDOM.render(
  <ThemeProvider theme={THEME}>
    <Provider store={store}>
      <Router history={history}>
        <Fragment>
          <Switch>
            <Redirect exact from="/" to="/nl/page/home" />
            <Route path="/:lang" component={App} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)
