import React, { Fragment } from 'react'

import Grid from '@material-ui/core/Grid' // To have fluid layout
import { withStyles } from '@material-ui/core/styles' // To have style only in this component
import Popup from '../Popup/Popup' // To give feedback

// Card for displaying the products
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

// For the adding to shopping cart icon
import Icon from '@material-ui/core/Icon'

// for links and translations
import I18n from 'components/I18n/I18n.js'
import I18nLink from 'components/I18n/I18nLink.js'

// Quantity input
import NumericInput from 'react-numeric-input' // http://vlad-ignatov.github.io/react-numeric-input/
// To format the currencies to EUR (and have our local decimals and stuff)
import { currencyFormat } from '../../utils/currencyFormat'

const styles = {
  product: {
    marginBottom: 10
  },
  card: {
    maxWidth: 400
  },
  cardHeader: {
    backgroundColor: '#deb7f3'
  },
  incrementer: {
    width: 50,
    height: 24
  },
  priceBox: {
    marginLeft: 7
  },
  addButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  }
}

// products & addItemToShoppingCart come through the props from ProductList in Shop
// classes comes from the props of this component (to assign styling to them)
const ProductList = ({products, addItemToShoppingCart, classes}) => {

  let message = ''
  
  return(

    <Fragment>

      <Grid container spacing={2} className={classes.product}>
        {
          products.map(product => {
            return (
              <Grid item key={product.id}>
                <Card className={classes.card}>
                  <CardHeader title={<I18n label={product.name} />} className={classes.cardHeader} />
                  <hr />
                  <CardContent>
                    <Typography component="div" className={classes.cardContent}>
                      <I18nLink to={'/products/' + product.id}>
                        <img src={product.img} alt={product.name} />
                      </I18nLink>
                      <I18n label={product.shortDescription} /><br /><br />
                      <I18nLink to={'/products/' + product.id}>
                        <Icon>zoom_in</Icon>
                      </I18nLink>
                      <br /><br />
                      <div className={classes.addButtons}>
                        { product.category === 'audio'
                          ?
                          <NumericInput id={`Qty${product.id}`} min={1} max={1} value={1} className={classes.incrementer} />
                          :
                          <NumericInput id={`Qty${product.id}`} min={1} value={1} className={classes.incrementer} />
                        }
                        <span className={classes.priceBox}>@ {currencyFormat.format(product.price)}</span>
                        <Icon onClick={(event) => addItemToShoppingCart(product.id)}>add_shopping_cart</Icon>
                        <br />
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>

      <Popup message={message} time={new Date()} />

    </Fragment>

  )

}

export default withStyles(styles)(ProductList)
