const initialState = []

// When IMAGES_RECEIVED action is picked up the imageReducer puts the payload
// in the images node in the redux store.
export default function imagesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'IMAGES_SUCCESS':
      return action.payload
    default:
      return state
  }
}
