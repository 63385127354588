import { ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_PRODUCT, SEND_PURCHASE_MAIL, EMPTY_CART, SETTLE_PURCHASE } from './types'
import axios from 'axios' // to be able to post to cloud functions

export const addProductToShoppingCart = (productToBeAdded) => {
  return (dispatch) => {
    dispatch({ type: ADD_PRODUCT, payload: productToBeAdded })
  }
}

export const removeProductFromShoppingCart = (productToBeRemoved) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_PRODUCT, payload: productToBeRemoved })
  }
}

export const updateProductShoppingCart = (productToBeUpdated) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_PRODUCT, payload: productToBeUpdated })
  }
}

export const mailPurchase = (shoppingCart, user) => {
  return (dispatch) => {
    dispatch({ type: SEND_PURCHASE_MAIL, payload: '' })
    axios
      .post(
        'https://us-central1-purebalance-58f8f.cloudfunctions.net/sendPurchase',
        { shoppingCart: shoppingCart.toJS(), userName: user.displayName, email: user.email },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ).then(function(response) {
        console.log(response)
        dispatch({ type: EMPTY_CART, payload: '' }) // so the shopping cart is emptied
      }).catch(function(error) {
        console.log(error)
      })
  }
}

// Only the token.id and the email of the user who is logged in is used, so we only send that

export const settlePurchase = (shoppingCart, i18n, token) => {

  return (dispatch) => {

    dispatch({ type: SETTLE_PURCHASE, payload: '' }) // to indicate payment settlement has begun


    const description = shoppingCart.reduce((description, item) => description + item.qty + ' x ' + i18n[i18n.lang][item.name] + ', ', '')

    console.log(description)

    // axios
    //   .post(
    //     'https://us-central1-purebalance-58f8f.cloudfunctions.net/settlePurchase',
    //     { shoppingCart: shoppingCart.toJS(), token },
    //     {
    //       headers: {
    //         'Content-Type': 'application/json'
    //       }
    //     }
    //   ).then(function(response) {
    //     console.log(response)
    //   }).catch(function(error) {
    //     console.log(error)
    //   })
  }
}
