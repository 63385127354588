// Basic react & redux stuff
import React, { Fragment } from 'react'
import { connect } from 'react-redux' // to have dispatch, so we can reset form
import { compose } from 'redux' // to string together stuff being applied to this component
import { reduxForm, Field, reset } from 'redux-form' // reduxForm to keep the state of everything in the form in the simplest way

// Stuff we use in the form
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import EnhancedTextField from '../EnhancedTextField/EnhancedTextField'

// The action creator to reset the password
import { sendPasswordReset } from '../../actions/users'
import { required, email } from 'utils/validations' // validation functions

// handleSubmit and submitting are from redux-form
// dispatch comes from connect (react-redux), we'll use it to reset the form
// closeLoginModal comes from props from index.js
const ForgottenPasswordForm = ({handleSubmit, submitting, dispatch, closeLoginModal}) => {

  function handleFormSubmit(values) {
    dispatch(sendPasswordReset(values.email))
    dispatch(reset('ForgottenPasswordForm')) // empty the form
    closeLoginModal()
  }

  return(
    <Fragment>
      <br />
      <h1>Paswoord vergeten</h1>
      <hr />
      <br />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Field
          name="email"
          label="Email"
          component={EnhancedTextField}
          validate={[email, required]}
        />
        <br />
        <Button type="submit" variant="contained" color="primary" disabled={submitting} onClick={handleSubmit(handleFormSubmit)}>
          Reset
          <Icon>send</Icon>
        </Button>
      </form>

    </Fragment>
  )
}

export default compose(
  connect(),
  reduxForm({
    form: 'ForgottenPasswordForm',
    destroyOnUnmount: false
  })
)(ForgottenPasswordForm)
