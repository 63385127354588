import React from 'react'
import { connect } from 'react-redux'

// to have a fluid container for the panels
import Grid from '@material-ui/core/Grid'
// for links and translations
import I18n from 'components/I18n/I18n.js'
import I18nLink from 'components/I18n/I18nLink.js'

// card toevoegen
// https://codesandbox.io/s/2z4683521y
// https://codesandbox.io/s/v0qvk0k0y5
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

import './panels.css'

const Panels = ({ i18n, panels }) => {

  // { i18n[i18n.lang][panel.link] }
  // { panel.url }

  // xs, extra-small: 0px or larger
  // sm, small: 600px or larger
  // md, medium: 960px or larger
  // lg, large: 1280px or larger
  // xl, extra-large: 1920px or larger

  // { i18n[i18n.lang][value.link.toUpperCase().concat('_PANEL')] }

  return(
    <Grid container className="panels" spacing={2}>
      {
        panels.map(function(value, index) {
          return (
            <Grid key={value.link} item md={4} sm={6} xs={12}>
              <Card className="panelCard">
                 <CardHeader title={value.link[0].toUpperCase() + value.link.replace(/_/, ' ').substring(1)} className={`panelHeader${index}`} />
                 <CardContent className="panelContent">
                    <I18nLink to={'/page/' + value.link}>
                      <img src={value.url} className="panelImg" alt={value.link[0].toUpperCase() + value.link.replace(/_/, ' ').substring(1)} />
                    </I18nLink>
                    <Typography component="p" className="panelDescription">
                      <I18n label={value.link.toUpperCase().concat('_PANEL')} />
                    </Typography>
                 </CardContent>
                 <CardActions className="panelActions">
                    <I18nLink to={'/page/' + value.link}>
                       <Icon>add_circle</Icon>
                    </I18nLink>
                 </CardActions>
              </Card>
            </Grid>
          )
        })
      }
    </Grid>
  )

}

const mapState = ({ i18n, panels }) => ({ i18n, panels })
export default connect(mapState)(Panels)
