import { firebase } from '../firebase'
import { MENU_SUCCESS } from './types'

// https://firebase.google.com/docs/database/web/lists-of-data
export const menuRequest = () => {
  return (dispatch) => {
    firebase
      .database()
      .ref('menu')
      .orderByChild('position')
      .once('value', (snapshot) => {
        let menu = []
        // you use orderbychild to order the stuff and you use snapshot.forEach to iterate over the result
        // in sorted order. You then stick the stuff in an array, because an object doesn't have the concept
        // or order
        snapshot.forEach(function(childSnapshot) {
          var childKey = childSnapshot.key
          var childData = childSnapshot.val()
          // first the parents themselves
          if(childData.parent === 0) {
            menu.push({
              key: childKey,
              name_button: childData.name_button,
              visible: childData.visible,
              children: []
            })
          }
        })
        // Then go over it again to add children
        snapshot.forEach(function(childSnapshot) {
          var childKey = childSnapshot.key
          var childData = childSnapshot.val()
          if(childData.parent !== 0) {
            menu.forEach(function(menuParent) {
              if(menuParent.key === childData.parent) {
                menuParent.children.push({
                  key: childKey,
                  name_button: childData.name_button,
                  visible: childData.visible
                })
              }
            })
          }
        })
        dispatch({ type: MENU_SUCCESS, payload: menu })
      })
  }
}
