const initialState = 0

export default function progressReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'PROGRESS_CHANGE':
      return action.payload
    default:
      return state
  }
}
