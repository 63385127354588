import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux' // to have dispatch

import { verifyEmail } from 'actions/users'

// - dispatch comes from connect
// - actionCode comes from AccountManagement and is gotten from url
const VerifyEmail = ({dispatch, actionCode}) => {

  // If this breaks remove the dispatch argument in the line directly below this one.
  useEffect(() => {
    if (actionCode) dispatch(verifyEmail(actionCode))
  }, [dispatch, actionCode])

  return(
    <Fragment>
      <h2>Validatie account</h2>
      <p>Je account is geactiveerd, je kan nu inloggen</p>
    </Fragment>
  )
}

export default connect()(VerifyEmail)
