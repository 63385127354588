import React from 'react'
import { DotLoader } from 'react-spinners' // https://www.react-spinners.com/

import './loader.css'

const Loader = () => {

  return (
    <div className='sweet-loading'>
      <DotLoader
        sizeUnit={"px"}
        size={60}
        color={'#d7d1be'}
        loading={true}
      />
    </div>
  )

}

export default Loader
