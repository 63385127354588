import React from 'react'
import Panels from '../Panels'

const Home = () => {
  return(
    <div>
      <Panels />
    </div>
  )
}

export default Home
