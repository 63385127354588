import { firebase } from '../firebase'
import { STYLES_SUCCESS } from './types'

// https://firebase.google.com/docs/database/web/lists-of-data
export const stylesRequest = () => {
  return (dispatch) => {
    firebase
      .database()
      .ref('styles')
      .orderByChild('position')
      .once('value', (snapshot) => {
        dispatch({ type: STYLES_SUCCESS, payload: snapshot.val() })
      })
  }
}
