import React, { Fragment } from 'react'
import { connect } from 'react-redux' // to have dispatch
import { compose } from 'redux' // to string together stuff when exporting component
import { reduxForm, Field } from 'redux-form' // to keep everything from form tidy

// Material-ui stuff
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

// Custom text field
import EnhancedTextField from 'components/EnhancedTextField/EnhancedTextField'

// To apply styles
import { withStyles } from '@material-ui/core/styles'

import { required } from 'utils/validations'

import history from 'utils/history'

// Action creator
import { resetPassword } from 'actions/users'

const styles = theme => ({
  fields: {
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 300
  }
})

// - dispatch comes from connect and it's to dispatch an action creator
// - handleSubmit & submitting come from redux-form
// - classes comes from the component itself to give it styling
// - actionCode is passed in from AccountManagement and it comes from the url (to verify to Google API that you can reset password)
const ResetPassword = ({dispatch, handleSubmit, submitting, classes, actionCode}) => {

  function handleFormSubmit(values) {
    dispatch(resetPassword(actionCode, values.newPassword))
    history.push('/')
  }

  return(
    <Fragment>
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit(handleFormSubmit)}>

        <Grid container direction="column">
          <Grid item className={classes.fields}>
            <Field
                name="newPassword"
                label="Paswoord"
                component={EnhancedTextField}
                validate={required}
            />
          </Grid>
        </Grid>

        <Grid item>
          <Button type="submit" variant="contained" color="primary" disabled={submitting}>
            Reset
            <Icon>send</Icon>
          </Button>
        </Grid>

      </form>
    </Fragment>
  )
}

export default compose(
  connect(),
  withStyles(styles),
  reduxForm({
    form: 'PasswordResetForm',
    destroyOnUnmount: false
  })
)(ResetPassword)
