//  This needs to be used

import axios from 'axios'
import { MAIL_SUCCESS } from './types'

export const mailRequest = (mail) => {

  return (dispatch) => {
    return axios
      .post(
        'https://us-central1-purebalance-58f8f.cloudfunctions.net/sendMail',
        {
          from: mail.from,
          to: mail.to,
          title: mail.title,
          body: mail.body
        }, // what is sent to the cloud function
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(function(response) {
        dispatch({ type: MAIL_SUCCESS })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

}
