import React from 'react'
import Grid from '@material-ui/core/Grid'

import './footer.css'

const Footer = () => (
  <Grid container direction="row" alignItems="center">
    <Grid item id="footerCopyright" xs={6}>
      © 2014 - {new Date().getFullYear()} - Pure Balance
    </Grid>
    <Grid item xs={6}>
      <Grid
        container
        id="externalLinks"
        direction="column"
        alignItems="flex-end"
      >
        <Grid item id="socialMediaBtns">
          <a
            href="https://www.facebook.com/NadiaVerhaegenCoaching/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://firebasestorage.googleapis.com/v0/b/purebalance-58f8f.appspot.com/o/fixed%2Ffacebook.png?alt=media&token=45aac749-083d-40f2-afce-7148bf3ae470" alt="facebook icon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC1YEymMC8zXitLs6yig6n-Q"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://firebasestorage.googleapis.com/v0/b/purebalance-58f8f.appspot.com/o/fixed%2Fyoutube.png?alt=media&token=741a0edf-0c0e-487a-acbe-df9b68f07738" alt="youtube icon" />
          </a>
          <a href="https://www.linkedin.com/in/nadiaverhaegen"
             target="_blank"
             rel="noopener noreferrer"
          >
            <img src="https://firebasestorage.googleapis.com/v0/b/purebalance-58f8f.appspot.com/o/fixed%2Flinkedin.png?alt=media&token=d9d69e2f-a181-4704-b65c-6277a0d708d9" alt="linkedin icon" />
          </a>
          <a href="https://twitter.com/NadiaVerhaegen"
             target="_blank"
             rel="noopener noreferrer"
          >
            <img src="https://firebasestorage.googleapis.com/v0/b/purebalance-58f8f.appspot.com/o/fixed%2Ftwitter.png?alt=media&token=1c655e8c-e5f2-4663-848f-0f4c8b9ee81c" alt="twitter icon" />
          </a>
        </Grid>
        <Grid item id="webCondor">
          <a href="https://www.webcondor.be"
             target="_blank"
             rel="noopener noreferrer"
          >
            Website by Webcondor
          </a>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

export default Footer
