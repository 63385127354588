import React from 'react'

import './blogs.css'

const Blogs = () => {

  return (
    <div>
      Here we will put the blogs
    </div>
  )
}

export default Blogs
