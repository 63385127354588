import React from 'react'
import Grid from '@material-ui/core/Grid'

import './banner.css'

import BannerImg from 'img/banner.jpg'

const Banner = () => (
  <Grid container direction="row" alignItems="center">
    <Grid item id="bannerPicture">
      <img src={BannerImg} alt="omschrijving van foto in banner" />
    </Grid>
  </Grid>
)

export default Banner
