// TODOS
// 1. Put the callback of the purchase in stripe instead

import React, { Fragment } from 'react'
import { connect } from 'react-redux' // To get stuff from redux-store and to dispatch actions
import { compose } from 'redux' // To string together stuff in export at the bottom

import Grid from '@material-ui/core/Grid' // Grid to have the shopping cart in a good format
import Button from '@material-ui/core/Button' // For purchase button
import Drawer from '@material-ui/core/Drawer' // Drawer for the shopping cart
import NumericInput from 'react-numeric-input' // Quantity input
import { withStyles } from '@material-ui/core/styles' // To apply styles on the drawer
import Popup from '../Popup/Popup' // To show feedback
import { currencyFormat } from '../../utils/currencyFormat' // To format the currencies to EUR (and have our local decimals and stuff)

import StripeCheckout from 'react-stripe-checkout' // Stripe for the payments
// For the different props in the element, such as shipping address for physical goods:
// https://www.npmjs.com/package/react-stripe-checkout

// Action creators
import { removeProductFromShoppingCart, updateProductShoppingCart, settlePurchase } from 'actions/shoppingCart' // Actions which will send payload and trigger the reducer to change the redux-store
import { addMessage } from 'actions/messages' // To show feedback through Popup / Snackbar component
import './shoppingCart.css' // apart from styles I have this too, so the width can be linked to the viewport better

const styles = {
  incrementer: {
    width: 50
  },
  paper: {
    background: '#F9F1D5'
  }
}

// - shoppingCart (List), user & i18n are gotten from the redux store
// - user is gotten from the redux-store to check whether the visitor is logged in
// - open and close are passed in through props on this component to open and close this subcomponent
// - classes come from the props of this component, to give it styling
// - dispatch comes from connect (react-redux), so we can dispatch actions, which are picked up by the reducer and redux-store is updated
const ShoppingCart = ({shoppingCart, user, i18n, open, close, classes, dispatch}) => {

  if(shoppingCart.length === 0) return null

  const total = shoppingCart.reduce((total, item) => total + item.qty * item.price, 0)

  // event is the quantity of the item with that id
  function changeQtyItemInShoppingCart(event, id) {
    dispatch(updateProductShoppingCart({id: id, qty: event}))
  }

  function removeItemFromShoppingCart(id) {
    dispatch(removeProductFromShoppingCart(id))
  }

  // handling the payment with the token which is being created when the customer provides their email,...
  function handleToken(token, addresses) {
    dispatch(settlePurchase(shoppingCart, i18n, token))
    // This will probably need to be in the callback of stripe????
    dispatch(addMessage("U ontvangt een mail met uw bestelling"))
  }

  return(

    <Fragment>

      <Drawer anchor="right" open={open} onClose={close} classes={{ paper: classes.paper }}>
        <div
          role="button"
          onKeyDown={close}
          className="shoppingCart"
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h1>Winkelkarretje</h1>
                <hr />
              </Grid>
              <Grid item xs={12}>
                {
                  (shoppingCart.size === 0 ?
                    "Geen producten"
                  :
                    shoppingCart.map(item => {
                      return (
                       <div key={item.id} className="cartItem">
                        <h2>{i18n[i18n.lang][item.name]}</h2>
                        <div>
                          { item.category === 'audio' ?
                            <NumericInput
                              id={`Qty${item.id}`}
                              min={1}
                              max={1}
                              value={item.qty}
                              className={classes.incrementer}
                              onChange={event => changeQtyItemInShoppingCart(event, item.id) }
                            />
                          :
                            <NumericInput
                              id={`Qty${item.id}`}
                              min={1}
                              value={item.qty}
                              className={classes.incrementer}
                              onChange={event => changeQtyItemInShoppingCart(event, item.id) }
                            />
                          }
                          &nbsp; @ {currencyFormat.format(item.price)}
                          = {currencyFormat.format(item.qty * item.price)}
                          &nbsp; (<span onClick={event => removeItemFromShoppingCart(item.id)}>verwijderen</span>)
                        </div>
                       </div>
                      )
                    })
                  )
                }
              </Grid>
              <Grid item xs={12}>
                <hr />
                <br />
                <h2>Totaal:&nbsp;{ currencyFormat.format(total) }</h2>
              </Grid>
              <Grid item xs={12}>

              </Grid>
              <Grid item xs={12}>
                {
                  !user
                  ?
                   <span>Je moet eerst inloggen vooraleer je een bestelling kan plaatsen!</span>
                  :
                  <StripeCheckout
                    name="Pure Balance"
                    description="Afrekenen"
                    stripeKey="pk_test_myUWCpEpbk1D4OKjGOf57k1U"
                    token={handleToken}
                    panelLabel="Betaal"
                    currency="EUR"
                    locale="nl"
                    amount={total * 100}
                  >
                    <Button variant="contained" color="primary" disabled={shoppingCart.size === 0}>
                      Afrekenen
                    </Button>
                  </StripeCheckout>
                }
              </Grid>
            </Grid>
        </div>
      </Drawer>

      <Popup />

    </Fragment>

  )

}

const mapState = ({ shoppingCart, user, i18n }) => ({ shoppingCart, user, i18n })
export default compose(
  withStyles(styles),
  connect(mapState)
)(ShoppingCart)
