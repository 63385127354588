import { List } from 'immutable'
const initialState = List()

export default function appointmentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'APPOINTMENTS_REQUEST': // when an appointment is booked it needs to be removed
      return action.payload
    default:
      return state
  }
}
