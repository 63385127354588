const initialState = []

export default function panelsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'PANELS_SUCCESS':
      return action.payload
    default:
      return state
  }
}
