// TO DOS
// - import dispatch sendMail..

import React, { Fragment } from 'react'
import axios from 'axios' // to post the content to a cloud function
import { connect } from 'react-redux' // to get

// Custom components
import ContactForm from './ContactForm'
// import NewsletterForm from 'components/Newsletter/NewsletterForm'
import Popup from 'components/Popup/Popup' // To show stuff in the message queue in the snackbar

// Action creators
import { addMessage } from 'actions/messages' // To show feedback through Popup / Snackbar component

// - dispatch comes from connect
const Contact = ({dispatch}) => {

  function sendContactMail(values) {
    axios.post(
      'https://us-central1-purebalance-58f8f.cloudfunctions.net/sendContact',
      values,
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then(function(response) {
      dispatch(addMessage('Je bericht is verzonden!'))
    })
    .catch(function(error) {
      console.log(error)
    })
  }

  return(
    <Fragment>
      <ContactForm onSubmit={sendContactMail} />
      { /* <hr /><NewsletterForm /> */ }
      <Popup />
    </Fragment>
  )

}

export default connect()(Contact)
