// TODOS
//  - Does message queue need to be an array or can we just have a string?
//  - When message is removed from the queue it just disappears on screen (instead of sliding off)

import React from 'react'
import { connect } from 'react-redux' // to have dispatch, so we can reset form
// From the snackbar to give feedback
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { removeMessage, removeError } from '../../actions/messages'

// Both messages and error come from the redux-store...
const Popup = ({messages, error, dispatch}) => {

  // if there is no error and no message just return
  if (!error && messages.length === 0) return null

  // if the snackbar is closed we dispatch an action to remove the message
  // and 0 is always the first one in the message queue, as it's an array I suppose
  function handleClose() {
    ( error ? dispatch(removeError(error)) : dispatch(removeMessage(messages[0])))
  }

  return(
    <Snackbar
     anchorOrigin={{
       vertical: 'bottom',
       horizontal: 'left',
     }}
     open={true}
     autoHideDuration={6000}
     onClose={handleClose}
     key={error || messages[0]}
     ContentProps={{
       'aria-describedby': 'message-id',
     }}
     message={error || messages[0]}
     action={[
       <IconButton
         key="close"
         aria-label="Close"
         color="inherit"
         onClick={handleClose}
       >
         <CloseIcon />
       </IconButton>,
     ]}
    />
  )
}

const mapState = ({messages, error}) => ({messages, error})
export default connect(mapState)(Popup)
