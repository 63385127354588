// Default react stuff
import React from 'react'
import { connect } from 'react-redux' // to connect to the redux state store and get the translations from i18n node

// - label is gotten from the properties (to indicate which field from the collection should be shown).
// - i18n is gotten from the redux state store and contains all the translations, for which the action creator was dispatched in App.js
// We check whether the language is set in the i18n object (lang in i18n object) and whether a translation can be found in i18n for the label being passed in through the props.
// If it finds the label in the translation object it returns the translation, if not it returns nothing.
const I18n = ({ label, i18n }) => (i18n[i18n.lang] && i18n[i18n.lang][label] ? <span dangerouslySetInnerHTML={{__html: i18n[i18n.lang][label] || label}}></span> : '')

const mapState = ({ i18n }) => ({ i18n })
export default connect(mapState)(I18n)
