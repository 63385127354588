// import { connectRoutes } from 'redux-first-router'

const initialState = {}

export default function pageReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'PAGES_SUCCESS':
      return action.payload
    default:
      return state
  }
}
