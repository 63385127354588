// As you can see in App.js there are two Components for 2 types of paths:
//  1) path={`${match.url}/page/:id`} => Page is shown (meta data is done by helmet)
//  2) path="/:lang/products/:id" => Product is shown (helmet for the meta data is in there too)

// The Page component renders a fragment in which 2 things are rendered:
//   1) The dynamic part of the page, which is gotten from the pages node in the state and whatever location
//      you're currently on (which it gets from the payload from the locationReducer which is tied into RFR).
//   2) The pre-written component if there is one, which it checks in an object with all the pre-written
//      components we have in the entire app.

// Default react stuff
import React, { Fragment } from 'react'
import { connect } from 'react-redux' // To access stuff in redux-store and to dispatch action creators

// Third party Components
import { Helmet } from 'react-helmet' // To put meta data in the meta tags
import Button from '@material-ui/core/Button' // To have the back button
import { Link } from 'react-router-dom'

// Custom components
import I18n from 'components/I18n/I18n' // The component for the translations in which the content is passed through the label prop
import Home from '../Home/Home' // Home page with the panels
import Blogs from '../Blogs/Blogs' // To search and read blogs
import Contact from '../Contact/Contact' // Contact form
import PageNotFound from '../PageNotFound/PageNotFound' // To show when page not found
import Shop from '../Shop/Shop' // Shop where customers can purchase stuff
import AccountManagement from '../AccountManagement/AccountManagement' // Landing page for email verification, password reset,...
import Appointments from '../Appointments/Appointments' // Where logged in users can make an appointment

import { withRouter } from 'react-router-dom' // To have access to match, so we can get stuff from path

// The object with all of the aformentioned pre-written components, which are imported
const staticContent = {
  home: Home,
  blogs: Blogs,
  contact: Contact,
  shop: Shop,
  account_management: AccountManagement,
  afspraken: Appointments
}

// - pages & styles come from the redux-store
// - match comes from react-router-dom (which is connected with withRouter at the bottom too) and is used
//   to get the id parameter from the url so the proper page can be returned.
const Page = ({ pages, styles, match  }) => {

  // Here we check whether the page from the payload is in the object with pre-written components above.
  // The result is in the third line of the fragment, if it's in there, include the component.
  const page = match.params.id || 'home' // If no page can be gotten from the payload (upon initial load), 'home' is used.
  const StaticContent = staticContent[page]
  const currentPage = pages[page.toLowerCase()]

  // Metadata: apparently keywords are useless, so just focus on the title and description
  // https://www.youtube.com/watch?v=ClXTdjsXKFU
  if (!currentPage) {
    return (
      <Fragment>
        <Helmet
          style={[{
            "cssText": `
              #banner {
                background-color: ` + styles.background_banner + `;
              }
            `
          }]}
        >
        </Helmet>
        <PageNotFound />
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <Helmet
          style={[{
            "cssText": `
              p {
                font-family: ` + styles.font_paragraphs + `;
              }
              h1 {
                font-family: ` + styles.font_titles + `;
              }
              body {
                background-color: ` + styles.background_main + `;
              }
              #banner {
                background-color: ` + styles.background_banner + `;
              }
              nav, #footer, .MuiAppBar-colorPrimary-8 {
                background-color: ` + styles.background_menu + `;
              }
              .active {
                background-color: ` + styles.background_active + `;
              }
              nav ul ul {
                background-color: ` + styles.background_dropdown + `;
              }
            `
          }]}
        >
          <title>{currentPage.meta_title}</title>
          <meta
            name="description"
            content={currentPage.meta_description}
          />
        </Helmet>
        <I18n label={currentPage.content} />
        {StaticContent && <StaticContent />}
        { page !== 'home' ? <Button component={Link} to="/">Terug</Button> : null }
      </Fragment>
    )
  }
}

const mapState = ({ pages, styles }) => ({ pages, styles })
export default connect(mapState)(withRouter(Page))
