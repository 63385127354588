import { firebase } from '../firebase'
import { PANELS_SUCCESS } from './types'

export const panelsRequest = () => {
  return (dispatch) => {
    return firebase
      .database()
      .ref('panels')
      .orderByChild('position')
      .once('value', (snapshot) => {
        let panels = []
        snapshot.forEach(function(childSnapshot) {
          panels.push({
            link: childSnapshot.key,
            url: childSnapshot.val().url
          })
        })
        dispatch({ type: PANELS_SUCCESS, payload: panels })
      })
  }
}
