// This reducer is indicates when loading is done
const initialState = {}

export default function loadingReducer(state = initialState, action = {}) {
  const { type } = action
  // it is triggered by all actions that end with _REQUEST, _SUCCESS or _FAILURE
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
  // it it's not, we ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === 'REQUEST'
  }
}
