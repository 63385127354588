// TODOS
//  - Close the modal when registering, telling the client to validate account

// Basic react & redux stuff
import React, { Fragment } from 'react'
import { connect } from 'react-redux' // to have dispatch, so we can reset form
import { compose } from 'redux' // to string together stuff being applied to this component
import { reduxForm, Field, reset } from 'redux-form' // reduxForm to keep the state of everything in the form in the simplest way

// Stuff we use in the form
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import EnhancedTextField from '../EnhancedTextField/EnhancedTextField'

// To give feedback
import Popup from '../Popup/Popup'

import { createUserWithEmailAndPassword } from '../../actions/users'

import { addMessage } from '../../actions/messages'

// validation functions
import { required, email } from 'utils/validations'

// - handleSubmit and submitting come from redux-form
// - dispatch comes from connect (react-redux), we'll use it to reset the form
// - closeLoginModal is brought in through the props to go to the login modal
const RegisterForm = ({handleSubmit, submitting, dispatch, closeLoginModal}) => {

  function handleFormSubmit(values) {
    dispatch(createUserWithEmailAndPassword(values)) // with this action creater we create the user profile, update it with the firstName and send verification email
    dispatch(reset('RegistrationForm')) // empty the form
    dispatch(addMessage('Je bent geregistreerd en kunt nu inloggen!'))
    closeLoginModal() // close modal
  }

  return(
    <Fragment>
      <br />
      <h1>Register</h1>
      <hr />
      <br />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Field
          name="firstName"
          label="Voornaam"
          component={EnhancedTextField}
          validate={required}
        />
        <Field
          name="email"
          label="Email"
          component={EnhancedTextField}
          validate={[email, required]}
        />
        <Field
          name="password"
          label="Paswoord"
          component={EnhancedTextField}
          validate={required}
        />
        <br />
        <Button type="submit" variant="contained" color="primary" disabled={submitting} onClick={handleSubmit(handleFormSubmit)}>
          Register
          <Icon>send</Icon>
        </Button>
      </form>

      <Popup />

    </Fragment>
  )
}

export default compose(
  connect(),
  reduxForm({
    form: 'RegistrationForm',
    destroyOnUnmount: false
  })
)(RegisterForm)
