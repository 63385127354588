// TODOS
//  - first click on next or previous doesn't work
//  - go to vandaag

import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux' // to have dispatch, so we can reset form

// Confirmation dialog
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { appointmentsRequest } from 'actions/appointments'

// To give feedback
import Popup from 'components/Popup/Popup'
import { addMessage } from 'actions/messages'

// To send a mail with the appointment
import { mailRequest } from 'actions/sendMail'

import 'moment/locale/nl-be'
import './calendar.css'

const CustomToolbar = (toolbar) => {

  const goToBack = () => {
    toolbar.date.setDate(toolbar.date.getDate() - 7)
    toolbar.onNavigate('prev')
  }

  const goToNext = () => {
    toolbar.date.setDate(toolbar.date.getDate() + 7)
    toolbar.onNavigate('next')
  }

  const label = () => {
    const date = moment(toolbar.date)
    return (
      <span>
        Week van {date.format('DD')}/{date.format('MM')}/{date.format('YYYY')}
      </span>
    )
  }

  return (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">{label()}</span>
      <span className="rbc-btn-group">
        <button onClick={goToBack}>&#8249;</button>
        <button onClick={goToNext}>&#8250;</button>
      </span>
    </div >
  )
}

// - dispatch comes from connect to both be able to dispatch messages or action creators and to get user from redux-store
// - user comes from redux-store, because someone needs to be logged in to be able to book an appointment
// - appointments comes from redux-store as well
const Appointments = ({dispatch, user, appointments}) => {

  const [appointmentDate, setAppointmentDate] = useState('')
  const [confirmationDialog, setConfirmationDialog] = useState(false)

  useEffect(() => {
    dispatch(appointmentsRequest())
  }, [dispatch])

  // Setup the localizer by providing the moment (or globalize) Object to the correct localizer, which was imported
  moment.locale('nl-be')
  const localizer = BigCalendar.momentLocalizer(moment)

  let myEventsList = []
  appointments.map((value, index) => {
    return myEventsList.push({
      key: value.key,
      title: 'Beschikbaar',
      start: moment(value.start).toDate(),
      end:  moment(value.end).toDate(),
      desc: 'Omschrijving'
    })
  })

  // Start time and end time of working day
  const minTime = new Date()
  minTime.setHours(9,0,0)
  const maxTime = new Date()
  maxTime.setHours(19,0,0)

  function openConfirmation(event) {
    if(!user) {
      dispatch(addMessage('Je moet eerst ingelogd zijn voor je een afspraak kan boeken!'))
    } else {
      setAppointmentDate(moment(event.start).format("DD/MM/YYYY HH:mm"))
      setConfirmationDialog(true)
    }
  }

  function closeConfirmation() {
    setConfirmationDialog(false)
  }

  function confirmAppointment() {
    setConfirmationDialog(false)
    dispatch(addMessage('Je krijgt een mail om je afspraak te bevestigen!'))
    dispatch(mailRequest({
      from: 'Pure Balance <webcondor.purebalance@gmail.com>',
      to: user.email,
      title: 'Bevestiging afspraak',
      body: 'Hallo ' + user.displayName + '<br /><br />Hierbij de bevestiging van je afspraak: ' + appointmentDate + '<br /><br />Opmerking: ' + document.getElementById('remarks').value + '<br /><br />Mvg,<br />Nadia'
    }))
  }

  return(
    <Fragment>

      <br />

      <BigCalendar
        localizer={localizer}
        events={myEventsList}
        defaultView='work_week'
        views={['work_week', 'day']}
        min={minTime}
        max={maxTime}
        components = {{toolbar : CustomToolbar}}
        onSelectEvent={(event) => {openConfirmation(event)}}
      />

      <Dialog
        open={confirmationDialog}
        onClose={closeConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Afspraak bevestigen"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Wil je de afspraak van {appointmentDate} bevestigen?
          </DialogContentText>
          <TextField
            id="remarks"
            label="Opmerkingen"
            multiline
            fullWidth
            rows="4"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmAppointment} color="primary">
            Bevestig
          </Button>
          <Button onClick={closeConfirmation} color="primary" autoFocus>
            Sluit
          </Button>
        </DialogActions>
      </Dialog>

      <Popup />

    </Fragment>
  )
}

const mapState = ({user, appointments}) => ({user, appointments})
export default connect(mapState)(Appointments)
