// Pages
export const PAGES_REQUEST = 'PAGES_REQUEST'
export const PAGES_SUCCESS = 'PAGES_SUCCESS'
export const PAGE = 'PAGE' // for pages with only dynamic content from the db

// Blogs
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS'
export const BLOG_ADDED = 'BLOG_ADDED'

// Menu
export const MENU_REQUEST = 'MENU_REQUEST'
export const MENU_SUCCESS = 'MENU_SUCCESS'
export const MENU_ITEM_ADDED = 'MENU_ITEM_ADDED'

// Panels
export const PANELS_REQUEST = 'PANELS_REQUEST'
export const PANELS_SUCCESS = 'PANELS_SUCCESS'

// Products
export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST'
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS'

// Translations
export const I18N_REQUEST = 'I18N_REQUEST'
export const I18N_SUCCESS = 'I18N_SUCCESS'
export const I18N_CHANGE_LOCALE = 'I18N_CHANGE_LOCALE'

// Shopping cart
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const SEND_PURCHASE_MAIL = 'SEND_PURCHASE_MAIL'
export const EMPTY_CART = 'EMPTY_CART'
export const SETTLE_PURCHASE = 'SETTLE_PURCHASE'

// Styles
export const STYLES_REQUEST = 'STYLES_REQUEST'
export const STYLES_SUCCESS = 'STYLES_SUCCESS'

// Mails
export const MAIL_SUCCESS = 'MAIL_SUCCESS'

// User management
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_CHECK = 'USER_LOGIN_CHECK'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const USER_CREATION_REQUEST = 'USER_CREATION_REQUEST'
export const USER_CREATION_SUCCESS = 'USER_CREATION_SUCCESS'
export const USER_CREATION_ERROR = 'USER_CREATION_ERROR'
export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR'
export const USER_EMAIL_VERIFICATION_REQUEST = 'USER_EMAIL_VERIFICATION_REQUEST'
export const USER_EMAIL_VERIFICATION_SUCCESS = 'USER_EMAIL_VERIFICATION_SUCCESS'
export const USER_EMAIL_VERIFICATION_ERROR = 'USER_EMAIL_VERIFICATION_ERROR'
export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST'
export const USER_PASSWORD_RESET_MAIL_SUCCESS = 'USER_PASSWORD_RESET_MAIL_SUCCESS'
export const USER_PASSWORD_RESET_CONFIRM = 'USER_PASSWORD_RESET_CONFIRM'
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS'
export const USER_PASSWORD_RESET_ERROR = 'USER_PASSWORD_RESET_ERROR'
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR'
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS'

// Messages / feedback / popups
export const MESSAGE_ADD_REQUEST = 'MESSAGE_ADD_REQUEST'
export const MESSAGE_REMOVE_REQUEST = 'MESSAGE_REMOVE_REQUEST'
// From the errors I removed REQUEST, because this doesn't entail communication with
// the server and shouldn't be picked up by the loading thingy
export const ERROR_REMOVE = 'ERROR_REMOVE'

// Appointments
export const APPOINTMENTS_REQUEST = 'APPOINTMENTS_REQUEST'
export const APPOINTMENT_ADD = 'APPOINTMENT_ADD'
