const initialState = ''

export default function errorReducer(state = initialState, action = {}) {

  switch (action.type) {

    case 'USER_LOGIN_ERROR':
    case 'USER_EMAIL_VERIFICATION_ERROR':
    case 'PAGE_ADD_ERROR':
    case 'USER_PASSWORD_RESET_ERROR':
      return action.payload

    case 'ERROR_REMOVE':
      return initialState

    default:
      return state

  }

}
