// All reducers listen to all actions flying around to check which ones are for them.
// RFR actions (so PAGE for example) are handled by the location reducer, which is provided by rfr (passed in to the root reducer in configureStore)
// Switcher component technically listens to changes in the location object, which is hooked up to the switcher through redux/connect.
import { combineReducers } from 'redux'
// For all the forms in the application: https://www.youtube.com/watch?v=ey7H8h4ERHg&t=1208s
import { reducer as formReducer } from 'redux-form'
import pageReducer from './pageReducer' // to dynamically add the current page to pages node in the store
import blogReducer from './blogReducer'
import i18nReducer from './i18nReducer'
import menuReducer from './menuReducer'
import imagesReducer from './imagesReducer'
import panelsReducer from './panelsReducer'
import styleReducer from './styleReducer'
import productsReducer from './productsReducer'
import shoppingCartReducer from './shoppingCartReducer'
import loadingReducer from './loadingReducer'
import userReducer from './userReducer'
import messagesReducer from './messagesReducer'
import errorReducer from './errorReducer'
import progressReducer from './progressReducer' // for the upload of images
import appointmentReducer from './appointmentReducer'

// The root of the redux state (and redux state can be thought of as a tree) is defined in this rootReducer
// Here we say that the root is connected to nodes one level below it: location, page, blogs,...
// And it says that locationReducer is responsible for what data is under the location node,
// pageReducer for what's under the page node, and blogReducer for what's under the blogs node.
// locationReducer comes from redux-first-router's connectRoutes, the location contains the url
// (eg blogs/whateverKeyForBlog), but also the parameters, like :id and stuff.
// At first visiting the site the state first gets the initial values of all the reducers and this is the only
// time the initial values are used in the reducers.
const rootReducer = combineReducers({
  form: formReducer,
  blogs: blogReducer,
  i18n: i18nReducer,
  pages: pageReducer,
  menu: menuReducer,
  images: imagesReducer,
  panels: panelsReducer,
  styles: styleReducer,
  products: productsReducer,
  shoppingCart: shoppingCartReducer,
  loading: loadingReducer,
  user: userReducer,
  messages: messagesReducer,
  error: errorReducer,
  progress: progressReducer,
  appointments: appointmentReducer
})

export default rootReducer
