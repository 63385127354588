// The store itself and applyMiddleware so thunk can be applied
import { createStore, applyMiddleware } from 'redux'
// Redux Thunk middleware allows you to write action creators that return a function instead of an action.
// The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain condition is met.
import thunk from 'redux-thunk'
// You need to install chrome plugin too, that way you can go back to an earlier state easily
// and to inspect the current state and what actions have been sent.
// This makes it possible to open the window with the redux store
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

// a file where we have all the routes
// import routesMap from '../routesMap'
// the main reducer which combines all the others
import rootReducer from '../reducers/rootReducer'

// here we export the function which is called in index.js
// preloadedState is put as argument, just if you want to start the application in a certain state
export default function configureStore(preloadedState) {

  // destructuring: 3 constants take what is returned by connectRoutes function
  // const { reducer, middleware, enhancer } = connectRoutes(history)

  // middleware comes from the destructuring above & thunk is at the top
  const middlewares = applyMiddleware(thunk)

  // 3 arguments:
  //  - reducer: to enable redux first router's location reducer gotten from line 25
  //  - preloadedState: the initial state, but it's optional, but this is why you have it as an argument in configureStore I suppose
  //  - enhancer
  const store = createStore(
      rootReducer,
      preloadedState,
      composeWithDevTools(middlewares)
    )

  return store
}
