import { I18N_SUCCESS, I18N_CHANGE_LOCALE } from '../actions/types'

const initialState = {
  lang: 'nl'
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case I18N_SUCCESS:
      return {
        ...state,
        [action.payload.lang]: action.payload.translation,
        lang: action.payload.lang
      }
    case I18N_CHANGE_LOCALE:
      return { ...state, lang: action.payload.lang }
    default:
      return action.payload && action.payload.lang && action.payload.lang !== state.lang ? { ...state, lang: action.payload.lang } : state
  }
}
