// Basic react & redux stuff
import React, { Fragment } from 'react'
import { connect } from 'react-redux' // to have dispatch and to make a connection with the redux-store
import { compose } from 'redux' // to string together stuff being applied to this component
import { reduxForm, Field, reset } from 'redux-form' // reduxForm to keep the state of everything in the form in the simplest way

// Stuff we use in the form
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import EnhancedTextField from '../EnhancedTextField/EnhancedTextField'

// To give feedback
import Popup from '../Popup/Popup'

import { required, email } from 'utils/validations'

// Action creators
//  - loginUserWithEmailAndPassword for the actual log in with email + password
//  - sendEmailVerification to resend the confirmation mail
import { loginUserWithEmailAndPassword } from 'actions/users'


// handleSubmit and submitting are from redux-form
// dispatch comes from connect, we'll use this to empty the form
// closeLoginModal comes through prop from menu (to close the modal when logged in)
// user and error comes from the redux-store, which are filled by the userReducer based on the actions from logging in
const LoginForm = ({ handleSubmit, submitting, dispatch, closeLoginModal, user, error }) => {

    // if someone is logged in, the component is re-rendered (user is prop) and it will close the login modal
    if(user) {
      closeLoginModal() // close modal
    }

    // When the form is submitted this function is carried out (see onSubmit of redux-form)
    function handleFormSubmit(values) {
      dispatch(loginUserWithEmailAndPassword(values))
      dispatch(reset('LoginForm')) // empty form, would put this in the if above, but causes error
    }

    return (
        <Fragment>
            <br />
            <h2>Login</h2>
            <hr />
            <br />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Field
                    name="email"
                    label="Email"
                    component={EnhancedTextField}
                    validate={[email, required]}
                />
                <Field
                    name="password"
                    label="Paswoord"
                    component={EnhancedTextField}
                    validate={required}
                />
                <br />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting}
                    onClick={handleSubmit(handleFormSubmit)}
                >
                    Login
                    <Icon>send</Icon>
                </Button>
            </form>

            <Popup />

        </Fragment>
    )
}

const mapState = ({ user, error }) => ({ user, error })

export default compose(
    connect(mapState),
    reduxForm({
        form: 'LoginForm',
        destroyOnUnmount: false
    })
)(LoginForm)
