import firebase from 'firebase/app'
import 'firebase/database' // to get stuff from the database
import 'firebase/auth'     // to be able to use the authorization stuff
import 'firebase/storage'  // to upload and delete an image

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBVAlTxwype9Ty-GA-Qaycs9XjI2og5KOk",
  authDomain: "purebalance-58f8f.firebaseapp.com",
  databaseURL: "https://purebalance-58f8f.firebaseio.com",
  projectId: "purebalance-58f8f",
  storageBucket: "purebalance-58f8f.appspot.com",
  messagingSenderId: "339166442609"
}

// this used to be const, but that doesn't make it out of the try to export
let app = undefined
let facebookProvider = undefined

try {
  app = firebase.initializeApp(config)
  facebookProvider = new firebase.auth.FacebookAuthProvider()
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)
  }
}

// For uploading & deleting images
const storage = app.storage()

export { firebase, app, facebookProvider, storage }
