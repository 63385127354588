import { MESSAGE_ADD_REQUEST, MESSAGE_REMOVE_REQUEST, ERROR_REMOVE } from './types'

export const addMessage = (message) => {
  return (dispatch) => {
    dispatch({ type: MESSAGE_ADD_REQUEST, payload: message })
  }
}

export const removeMessage = (message) => {
  return (dispatch) => {
    dispatch({ type: MESSAGE_REMOVE_REQUEST, payload: message })
  }
}

export const removeError = (error) => {
  return (dispatch) => {
    dispatch({ type: ERROR_REMOVE, payload: '' })
  }
}
