const initialState = []

export default function styleReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'STYLES_SUCCESS':
      return action.payload
    default:
      return state
  }
}
