import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { Set } from 'immutable'

import Grid from '@material-ui/core/Grid'

// To show feedback
import Popup from '../Popup/Popup'

// To apply styles on the drawer
import { withStyles } from '@material-ui/core/styles'

// To show popup feedback
import { addMessage } from '../../actions/messages'

// New subcomponent we pass the filtered list into
import ProductList from './ProductList'

import TablePagination from '@material-ui/core/TablePagination'

// To have the shopping cart available everywhere we have to put it in the redux store,
// so we create actions, which we dispatch, these are picked up by the shoppingCartReducer
// and they are changed on the shoppingCart node in the redux store, where the
// ShoppingCart component connects to it.
import { addProductToShoppingCart } from 'actions/shoppingCart'

const styles = {
    card: {
        maxWidth: 400
    },
    cardHeader: {
        backgroundColor: '#deb7f3'
    },
    addButtons: {
        display: 'flex',
        flexDirection: 'row'
    },
    priceBox: {
        marginLeft: 7
    },
    incrementer: {
        width: 50,
        height: 24
    },
    product: {
        marginBottom: 10
    },
    searchBox: {
        width: 300
    }
}

// products are gotten from the redux store
// classes is just the props of this component
// dispatch is gotten from connect and is used to dispatch the shoppingCart actions
const Shop = ({ i18n, products, classes, dispatch }) => {

    const [searchCriterium, setSearchCriterium] = useState() // what's typed in the search input
    const [sortCriterium, setSortCriterium] = useState() // sorting dropdown
    const [categoryCriterium, setCategoryCriterium] = useState('all') // category dropdown
    const [subCategoryCriterium, setSubCategoryCriterium] = useState('all') // category dropdown

    const [subCategories, setSubCategories] = useState(Set())

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)

    // If there are no products or the translation isn't there yet nothing is returned
    // And as those two are props, the component will be re-rendered when they change
    if (
      products.size === 0 ||
      !i18n[i18n.lang] ||
      i18n[i18n.lang].length === 0
    )
      return null

    // 1) SHOPPING CART
      function addItemToShoppingCart(id) {
        const product =  products.find(product => product.id === id)
        dispatch(addProductToShoppingCart({
            id: product.id,
            category: product.category,
            name: product.name,
            qty: parseInt(document.getElementById('Qty' + product.id).value),
            price: product.price
        }))
        // setValue is described in the documentation of react-numeric-input
        document.getElementById('Qty' + product.id).setValue(1)
        dispatch(addMessage('Product werd toegevoegd aan uw karretje!'))
      }

    // 2) SEARCHING / SORTING / FILTERING / PAGE CHANGES

      // 2.1) Searching by typing in inputbox
        function onSearchChange(event) {
          setSearchCriterium(event.target.value)
          setPage(0)
        }

        // .filter() goes over products and this function is passed in
        // and for every value (product) it checks whether searchCriterium (what is being typed in search box is set as filter) is in there.
        // If there is a searchCriterium (whatever you type in the search box) it returns true when it's found in the product, otherwise false.
        // When there is no searchCriterium filled in it returns true, so then all products need to be shown.
        // I go into the i18n object, which contains the translations. I convert both the search and the translation to
        // lower case, so we're sure that the search string has every chance to get found.
        function searchFilter(product) {
          return searchCriterium ? i18n[i18n.lang][product.name].toLowerCase().indexOf(searchCriterium.toLowerCase()) !== -1 : true
        }

      // 2.2) Sorting in price / name
        function onSortChange(event) {
          setSortCriterium(event.target.value)
          setPage(0)
        }

        function productSort(product1, product2) {
          if (sortCriterium) {
            return product1[sortCriterium] > product2[sortCriterium]
              ? 1
              : product2[sortCriterium] > product1[sortCriterium]
              ? -1
              : 0
          } else {
            return 0
          }
        }

      // 2.3) Filtering on category
      function onCategoryChange(event) {
         setCategoryCriterium(event.target.value) // categoryCriterium is used in the categoryFilter
         setPage(0) // to switch the gallery back to the first page
         // loop over the products and if the category is the selected one, put the sub category in the subCategories set
        setSubCategories(Set(products.filter(product => product.category === event.target.value)).map(function(product) {
         return(product.subCategory)
        }))
        setSubCategoryCriterium('all')
      }

        function categoryFilter(product) {
          if(categoryCriterium === 'all') {
            return true
          } else {
            return categoryCriterium ? ( product.category === categoryCriterium ) : false
          }
        }

        // A set is an immutabe js data structure, which renders the content unique.
        // We pass in an anonymous function in the mapping, which takes the product as an argument
        // and it returns the category, it returns all of them, even the double ones.
        // Set is what makes it unique.
        const categories = Set(products.map(product => product.category))

      // 2.4) Filtering on subCategory
      function onSubCategoryChange(event) {
        setSubCategoryCriterium(event.target.value) // categoryCriterium is used in the categoryFilter
        setPage(0) // to switch the gallery back to the first page
      }

        function subCategoryFilter(product) {
          if(subCategoryCriterium === 'all') {
            return true
          } else {
            return subCategoryCriterium ? ( product.subCategory === subCategoryCriterium ) : false
          }
        }

      // 2.5) Changing pages
        function handleChangePage(event, page) {
          setPage(page)
        }

        function handleChangeRowsPerPage(event) {
          setRowsPerPage(event.target.value)
        }

    return (
        <Fragment>
            <br />
            <input
                className={classes.searchBox}
                onChange={onSearchChange}
                placeholder={i18n[i18n.lang]['AAA_SEARCH']}
            />
            <br />

            <Grid container>
              <Grid item xs={3}>
                <span>{i18n[i18n.lang]['AAA_SORT']}:</span>
                <br />
                <select className={classes.searchBox} onChange={onSortChange}>
                    <option value='name'>{i18n[i18n.lang]['AAA_SORT_NAME']}</option>
                    <option value='price'>{i18n[i18n.lang]['AAA_SORT_PRICE']}</option>
                </select>
              </Grid>
              <Grid item xs={3}>
                <span>{i18n[i18n.lang]['AAA_FILTER']}:</span>
                <br />
                <select className={classes.searchBox} onChange={onCategoryChange} id="productCategory">
                  <option key="all" value="all">(Alle categoriën)</option>
                  { categories.sort().map((value) => <option key={value} value={value}>{value.charAt(0).toUpperCase() + value.slice(1)}</option>) }
                </select>
              </Grid>
              <Grid item xs={3}>
              {
                subCategories.size > 0
                ?
                  <Grid item xs={3}>
                    <span>SUUUUB:</span>
                    <br />
                    <select className={classes.searchBox} onChange={onSubCategoryChange}>
                      <option key="all" value="all">(Alle categoriën)</option>
                      { subCategories.sort().map((value) => <option key={value} value={value}>{value.charAt(0).toUpperCase() + value.slice(1)}</option>) }
                    </select>
                  </Grid>
                :
                  <span></span>
              }
              </Grid>
            </Grid>

            <br />

            <ProductList
                products={products
                    .filter(searchFilter)
                    .filter(categoryFilter)
                    .filter(subCategoryFilter)
                    .sort(productSort)
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)}
                addItemToShoppingCart={addItemToShoppingCart}
            />

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={products.filter(searchFilter).filter(categoryFilter).sort(productSort).size}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage={i18n[i18n.lang]['AAA_RPP']}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page'
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page'
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

            <Popup />

        </Fragment>
    )
}

const mapState = ({ i18n, products }) => ({ i18n, products })
export default compose(
    connect(mapState),
    withStyles(styles)
)(Shop)
