// TO DOS
//  - complete the reset password errors?
//  - firebase & app are very similar, being exported in firebase.js
//  - in loginUserWithEmailAndPassword, authListener & verifyEmail perhaps add a sending of the verification mail?

import { firebase } from '../firebase'
import {
  USER_CREATION_REQUEST,
  USER_CREATION_SUCCESS,
  USER_CREATION_ERROR,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_ERROR,
  USER_EMAIL_VERIFICATION_REQUEST,
  USER_EMAIL_VERIFICATION_SUCCESS,
  USER_EMAIL_VERIFICATION_ERROR,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_CONFIRM,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_ERROR,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  USER_FETCH_SUCCESS,
  EMPTY_CART // so shopping cart can be emptied
} from './types'

import { addMessage } from 'actions/messages' // to give feedback

export const createUserWithEmailAndPassword = (values) => {
  return (dispatch) => {
    dispatch({ type: USER_CREATION_REQUEST, payload: '' })
    firebase.auth().createUserWithEmailAndPassword(values.email, values.password).then((user) => {
      dispatch(updateProfileDisplayName(values.firstName))
      dispatch({ type: USER_CREATION_SUCCESS, payload: '' })
      dispatch(addMessage('Je krijgt een mail om je account te activeren!'))
    }).catch(function(error) {
      switch (error.code) {
        case 'auth/weak-password':
          dispatch(addMessage('Gelieve een langer paswoord te kiezen!'))
          break
        case 'auth/email-already-in-use':
          dispatch(addMessage('Dit email adres is al geregistreerd!'))
          break
        case 'auth/invalid-action-code':
          dispatch(addMessage('De code is niet geldig meer, gelieve u opnieuw te registreren!'))
          break
        default:
          dispatch(addMessage('Er is iets misgegaan, gelieve nog eens te proberen!'))
      }
      dispatch({ type: USER_CREATION_ERROR, payload: 'Er is iets misgegaan met het aanmaken van de user' })
    })
  }
}

export const updateProfileDisplayName = (firstName) => {
  return (dispatch) => {
    dispatch({ type: USER_PROFILE_UPDATE_REQUEST, payload: '' })
    firebase.auth().currentUser.updateProfile({ displayName: firstName }).then(() => {
      dispatch(sendEmailVerification())
      dispatch({ type: USER_PROFILE_UPDATE_SUCCESS, payload: '' })
    }).catch(function(error) {
      dispatch({ type: USER_PROFILE_UPDATE_ERROR, payload: 'Er is iets misgegaan met het updaten van het profiel' })
    })
  }
}

export const sendEmailVerification = () => {
  return (dispatch) => {
    dispatch({ type: USER_EMAIL_VERIFICATION_REQUEST, payload: '' })
    firebase.auth().currentUser.sendEmailVerification().then(() => {
      dispatch({ type: USER_EMAIL_VERIFICATION_SUCCESS, payload: firebase.auth().currentUser })
    }).catch(function(error) {
      dispatch({ type: USER_EMAIL_VERIFICATION_ERROR, payload: 'Er is iets misgegaan met het versturen van de mail' })
    })
  }
}

export const loginUserWithEmailAndPassword = (values) => {
  return (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST, payload: '' })
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(function() {
        // Existing and future Auth states are now persisted in the current session only.
        // Closing the window would clear any existing state even if a user forgets to sign out.
        // A new sign-in will be persisted with session persistence.
        return firebase.auth().signInWithEmailAndPassword(values.email, values.password).then((data) => {
          // Check whether email was verified
          if(!firebase.auth().currentUser.emailVerified) {
            dispatch({ type: USER_LOGIN_ERROR, payload: 'Je account moet nog gevalideerd worden!' })
          } else {
            dispatch({ type: USER_LOGIN_SUCCESS, payload: firebase.auth().currentUser })
          }
        }).catch((error) => {
          dispatch({ type: USER_LOGIN_ERROR, payload: 'Fout email of paswoord!' })
        })
      })
      .catch(function(error) {
        // Handle Errors here.
        console.log(error.code + ' - ' + error.message)
      })
  }
}

export const sendPasswordReset = (email) => {
  return (dispatch) => {
    dispatch({ type: USER_PASSWORD_RESET_REQUEST, payload: '' })
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      dispatch({ type: USER_PASSWORD_RESET_SUCCESS, payload: '' })
      dispatch(addMessage('Er is een mail met een link verstuurd om je paswoord te resetten.'))
    }).catch(function(error) {
      dispatch({ type: USER_EMAIL_VERIFICATION_ERROR, payload: 'Email adres bestaat niet in onze database' })
    })
  }
}

export const resetPassword = (code, newPassword) => {
  return (dispatch) => {
    dispatch({ type: USER_PASSWORD_RESET_CONFIRM, payload: '' })
    firebase.auth().confirmPasswordReset(code, newPassword)
      .then(function() {
        dispatch({ type: USER_PASSWORD_RESET_SUCCESS, payload: '' })
        dispatch(addMessage('Paswoord veranderd, je kan nu inloggen.'))
      })
      .catch((error) => {

        console.log(error.code)
        switch(error.code) {
          case 'auth/weak-password':
            dispatch({ type: USER_PASSWORD_RESET_ERROR, payload: 'Je paswoord moet minstens 6 karakters lang zijn' })
            break

          default:
            dispatch({ type: USER_PASSWORD_RESET_ERROR, payload: 'Er is iets mis gegaan, gelieve nog eens te proberen' })

        }



      })
  }
}

export const logOut = () => {
  return (dispatch) => {
    firebase.auth().signOut().then(function() {
      dispatch({ type: USER_LOGOUT_SUCCESS, payload: '' })
      dispatch({ type: EMPTY_CART, payload: '' }) // so that shopping cart is emptied
    }, function(error) {
      dispatch({ type: USER_LOGOUT_ERROR, payload: '' })
    })
  }
}

export const fetchUser = () => {
  return (dispatch) => {
    dispatch({ type: USER_FETCH_SUCCESS, payload: firebase.auth().currentUser })
  }
}

// This only has to be called once (useEffect [], which it is in App.js)
export const authListener = () => {
  return (dispatch) => {
    firebase.auth().onAuthStateChanged((user) => {
      // check if there is a user, otherwise the check for emailVerified gives an error
      if(user) {
        // check if there is a user with verified email before auth may listen
        if(user.emailVerified) {
          // Check the token whether custom claim was of isAdmin was added
          firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
            // !! make sure it's a boolean value
            dispatch({ type: USER_LOGIN_SUCCESS, payload: { ...user, isAdmin: !!idTokenResult.claims.isAdmin } })
          }).catch((error) => {
            dispatch({ type: USER_LOGIN_ERROR, payload: 'Er ging iets mis, neem contact op met de admin!' })
          })
        }
      }
    })
  }
}

export const verifyEmail = (actionCode) => {
  return (dispatch) => {
    firebase.auth().applyActionCode(actionCode).then(function(resp) {
      dispatch({ type: USER_EMAIL_VERIFICATION_SUCCESS, payload: { ...firebase.auth().currentUser, emailVerified: true } })
      dispatch(addMessage('Je account is actief en je bent ingelogd!'))
    }).catch(function(error) {
      dispatch({ type: USER_EMAIL_VERIFICATION_ERROR, payload: 'De validatie mail was niet geldig meer, we versturen een nieuwe!' })
      sendEmailVerification()
    })
  }
}
