// TODOS
//  - Solve the setting of feedback in snackbar, it gets messed up after a few clicks
//  - Maybe put this in the Shop component folder as well?

// As you can see in App.js there are two Components for 2 types of paths:
//  1) path={`${match.url}/page/:id`} => Page is shown (meta data is done by helmet)
//  2) path="/:lang/products/:id" => Product is shown (helmet for the meta data is in here too)

// The Product component is shown when someone clicks to get more information of a product in the shop. It renders a fragment 
// in which either the translated information of the product is shown or the ProductNotFound component. 

// Default react stuff
import React, { Fragment } from 'react'
import { connect } from 'react-redux' // To have dispatch and be able to get stuff from the redux-store
import { compose } from 'redux' // To string together stuff in the export at the bottom
import { withRouter } from 'react-router-dom' // To get info from the path

// Third party Components
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import { Helmet } from 'react-helmet' // For meta data
import { withStyles } from '@material-ui/core/styles' // To have style only in this component
import NumericInput from 'react-numeric-input' // Quantity input: http://vlad-ignatov.github.io/react-numeric-input/

// Custom components
import I18nLink from 'components/I18n/I18nLink.js' // For the back button
import Popup from 'components/Popup/Popup' // To show feedback
import ProductNotFound from 'components/ProductNotFound/ProductNotFound' // When the product is not found

// Action creators
import { i18nRequest } from 'actions/i18n' // to get translations
import { addMessage } from 'actions/messages' // to dispatch a message to the message queue
import { addProductToShoppingCart } from 'actions/shoppingCart' // To have the shopping cart available everywhere we have to put it in the redux store,
// so we create actions, which we dispatch, these are picked up by the shoppingCartReducer and they are changed on the shoppingCart node in the redux store, where the
// ShoppingCart component connects to it.

// Utils
import { currencyFormat } from 'utils/currencyFormat' // To format the currencies to EUR (and have our local decimals and stuff)

const localStyles = {
    incrementer: {
        width: 50,
        height: 24
    },
    priceBox: {
        marginLeft: 7
    },
    addButtons: {
        display: 'flex',
        flexDirection: 'row'
    },
    img: {
        alignSelf: 'flex-start'
    }
}

// dispatch comes from connect and makes that we can dispatch actions
// match comes from react-router-dom (withRouter)
// styles comes from redux-store, this isn't a page, so styling needs to be pulled in here too
const Product = ({ dispatch, match, i18n, products, styles, classes }) => {


    // App.js loads the default language when just refreshing on this Product page.
    // When you come through a link in the Shop page and you are in a different language, then it's already loaded.
    // But here we need to check whether the language from the url is already loaded in i18n node, if not action needs to be dispatched
    if (!i18n[match.params.lang]) {
        dispatch(i18nRequest(match.params.lang))
    }

    // Even if someone gets this link App.js is loaded obviously and everything is loaded into redux-store,
    // so there is no reason to worry about that...
    // I don't think I need to do the following test though... coz of that... but not sure....
    if (products.length === 0 || !i18n[match.params.lang]) return null

    // Add to the shoppingCart needs to be available here too and it dispatches the same action
    function addItemToShoppingCart(product) {
      dispatch(
        addProductToShoppingCart({
          id: product.id,
          name: i18n[i18n.lang][product.name],
          qty: parseInt(document.getElementById('Qty' + product.id).value),
          price: product.price
        })
      )
      document.getElementById('Qty' + product.id).setValue(1) // setValue is described in the documentation of react-numeric-input
      dispatch(addMessage('Product werd toegevoegd aan uw karretje!'))
    }

    // The long way:
    // const indexOfProduct = products.findIndex(product => product.id === match.params.id)
    // const product = products.get(indexOfProduct)
    // The short way:
    const product = products.find(product => product.id === match.params.id)
    
    if (!product) {
      return (
        <Fragment>
          <Helmet
            style={[{
              "cssText": `
                #banner {
                  background-color: ` + styles.background_banner + `;
                }
              `
            }]}
          >
          </Helmet>
          <ProductNotFound></ProductNotFound>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Helmet
            style={[{
              "cssText": `
                 p {
                   font-family: ` + styles.font_paragraphs + `;
                 }
                 h1 {
                   font-family: ` + styles.font_titles + `;
                 }
                 body {
                   background-color: ` + styles.background_main + `;
                 }
                 #banner {
                   background-color: ` + styles.background_banner + `;
                 }
                 nav, #footer, .MuiAppBar-colorPrimary-8 {
                   background-color: ` + styles.background_menu + `;
                 }
                 .active {
                   background-color: ` + styles.background_active + `;
                  }
                  nav ul ul {
                   background-color: ` + styles.background_dropdown + `;
                  }
                `
              }]}
          >
          <title>{i18n[match.params.lang][product.name]}</title>
          <meta
            name="description"
            content={i18n[match.params.lang][product.shortDescription]}
          />
        </Helmet>
        <h1>{ i18n[match.params.lang][product.name] }</h1>
        <img
           src={product.img}
           alt={product.name}
           className={classes.img}
        />
        <br />
        <div>{ i18n[match.params.lang][product.longDescription] }</div>
        <br />
        <div className={classes.addButtons}>
            <NumericInput
                id={`Qty${product.id}`}
                min={1}
                value={1}
                className={classes.incrementer}
             />
             <span className={classes.priceBox}>@{' '}{currencyFormat.format(product.price)}</span>
             <Icon onClick={event => addItemToShoppingCart(product)} >
                add_shopping_cart
             </Icon>
             <br />
        </div>
        <br />
        <I18nLink to={'/page/shop/'}>
           <Button variant="contained" color="primary">Terug</Button>
        </I18nLink>
        <br />

        <Popup />

        </Fragment>
      )
   }
}

const mapState = ({ i18n, products, styles }) => ({ i18n, products, styles })
export default compose(
    connect(mapState),
    withStyles(localStyles),
    withRouter
)(Product)
