import { firebase } from '../firebase'
import { I18N_SUCCESS, I18N_CHANGE_LOCALE } from './types'

// In LanguageSwitcher when a language button is pushed, the action which is created by this action creator is dispatched.
export const i18nChangeLocale = lang => {
  // Both dispatch and getState come from redux-thunk middleware
  //  - getState is a function from redux-thunk middleware which gets all of the state (every node)
  //  - dispatch dispatches the action that the action creator creates (once redux-thunk has done its middleware thing)
  return dispatch => {
    // taking properties from the location node:
    // - type comes from routesMap key (which is [evaluated])
    // - payload is the : elements from the url (so lang, id and probably page in the near future when those become dynamic)
    // Type and payload come from whatever is in location in the redux state
    // the payload object is spread and lang is replaced
    dispatch({ type: I18N_CHANGE_LOCALE, payload: { lang } })
  }
}

export const i18nRequest = lang => {
  return dispatch => {
    firebase
      .database()
      .ref('i18n')
      .once('value', snapshot => {
        const translation = snapshot.val()[lang]
        dispatch({ type: I18N_SUCCESS, payload: { lang, translation } })
      })
  }
}
