import { APPOINTMENTS_REQUEST } from './types'
import { firebase } from '../firebase'
import { List } from 'immutable'

import { addMessage } from 'actions/messages'

export const appointmentsRequest = () => {
  return (dispatch) => {
    return firebase
      .database()
      .ref('appointments')
      .orderByChild('start')
      .once('value', (snapshot) => {
        let appointments = List()
        // you use orderbychild to order the stuff and you use snapshot.forEach to iterate over the result
        // in sorted order. You then stick the stuff in an immutable list
        snapshot.forEach(function(childSnapshot) {
          appointments = appointments.push({
            key: childSnapshot.key,
            start: childSnapshot.val().start,
            end: childSnapshot.val().end
          })
        })
        dispatch({ type: APPOINTMENTS_REQUEST, payload: appointments })
      })
  }
}

export const addAppointment = (startDate, endDate) => {
  return dispatch => {

    // dispatch({ type: APPOINTMENT_ADD, payload: {startDate, endDate} })

    firebase
      .database()
      .ref('appointments/')
      .push({ start: startDate, end: endDate })
      .then(() => {
        console.log('done')
      })
      .catch((error) => {
        console.log(error)
      })

    // var newPostRef = firebase.database().ref('appointments').push()
    // newPostRef.set({
    //   start: startDate,
    //   end: endDate
    // }).then(() => {
    //   console.log('done')
    // })
    // .catch((error) => {
    //   console.log(error)
    // })

  }
}

export const deleteAppointment = (key) => {
  return dispatch => {
    const appointmentToBeDeleted = firebase.database().ref('appointments/' + key)
    appointmentToBeDeleted.remove()
    dispatch(addMessage('De afspraak is verwijderd!'))
  }
}

export const updateAppointment = (key, start, end) => {
  return dispatch => {
    const updateAppointment = {}
    updateAppointment[key + '/start'] = start
    updateAppointment[key + '/end'] = end
    firebase.database().ref('appointments/').update(updateAppointment)
    dispatch(addMessage('De afspraak werd aangepast!'))
  }
}



export const appointmentListener = () => {
  return dispatch => {
    const appointmentsRef = firebase.database().ref('appointments/').orderByChild('start')
    appointmentsRef.on('value', function(snapshot) {
      // console.log(snapshot.val())
      // updateStarCount(postElement, snapshot.val());
      let appointments = List()
      // you use orderbychild to order the stuff and you use snapshot.forEach to iterate over the result
      // in sorted order. You then stick the stuff in an immutable list
      snapshot.forEach(function(childSnapshot) {
        appointments = appointments.push({
          key: childSnapshot.key,
          start: childSnapshot.val().start,
          end: childSnapshot.val().end
        })
      })
      dispatch({ type: APPOINTMENTS_REQUEST, payload: appointments })
    })
  }
}
