// TO DO
// MENU ITEM ADDED??

const initialState = []

// When MENU_RECEIVED action is picked up the menuReducer puts the payload (the menu I assume)
// in the menu node, which then can be gotten from the state store.
export default function menuReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'MENU_ITEM_ADDED':
      return { }
    case 'MENU_SUCCESS':
      return action.payload
    default:
      return state
  }
}
