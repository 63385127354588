// Have state be a List (to ensure immutability) https://immutable-js.github.io/immutable-js/docs/#/List
// Aside from a bunch of nice functions, the most important part of immutable is that it
// returns a new list without modifying the original one (immutable).
// Only use fromJS and toJS when you want to convert from/to regular javascript types to immutable.js types,
// but in this case we will be working with the list here and in the component itself
import { List } from 'immutable'
const initialState = List()

export default function shoppingCartReducer(state = initialState, action = {}) {

  switch (action.type) {

    case 'ADD_PRODUCT':
      const index1 = state.findIndex(item => item.id === action.payload.id)
      return index1 === -1 ? state.push(action.payload) : state.update(index1, value => ({ ...action.payload, qty: state.get(index1).qty + action.payload.qty }))

    case 'REMOVE_PRODUCT':
      // the payload is the id itself, so don't need action.payload.id
      return state.delete(state.findIndex(item => item.id === action.payload))

    case 'UPDATE_PRODUCT':
      const index2 = state.findIndex(item => item.id === action.payload.id)
      return state.update(index2, value => ({ ...state.get(index2), qty: action.payload.qty }))

    case 'EMPTY_CART':
      return initialState

    default:
      return state

  }
}
