// Reducers take state & an action to be applied to that state and return a new state.
// All of the state is kept in the redux store, which is defined in the rootReducer.
// This blogReducer listens for actions and then updates what is in the blogs node of the state
// Here you put the initialState, but is there a reason why not just to put state = {}, like
// with the action?
const initialState = {}

// So it listens for actions with 1 of these 2 action types, right? If they see those pass by they
// return something... and in doing so they change the blogs node of the redux state?
// So the actions should always be written together with the corresponding reducer more or less?
// So here we say there will be 2 actions we'll be watching for, BLOG_ADDED and BLOGS_RECEIVED
// Would it be good to import the action types in here and map over that (if I put the types in an object)?
export default function blogReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'BLOG_ADDED':
    // The action dispatches this: ({ type: BLOG_ADDED, payload: { key: blog.key, blog: blog } })
    // So the reducer returns this to the blogs node of the redux state.
    // So you do the spread operator on the initialState (current state I suppose) and you add
    // blog, which was added to the db by the action?
      return { [action.payload.key]: action.payload.blog, ...state }
    case 'BLOGS_SUCCESS':
      return action.payload.blogs
    default:
      return state
  }
}
